* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-top: 3px
}

#root {
    display: flex;
    justify-content: center;
}

.App {
    width:  800px;
}

.settingcreen {
    width:  100%;
}

.post {
    display: flex;
    padding: 9px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: top;
    border-radius: 10px;
}

.colorset {
    display: flex;
    padding: 10px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.code {
    white-space: pre-line;
}

.perenos {
    white-space: pre-wrap;
}

.print-agreement {
    margin: 19px;
}

.vertical span {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
}
  
.border {
border:  0px;
}

.table {
font-size: 12px;
}

.tableAct {
    font-size: 16px;
    
}

.tableAct1 {
    font-size: 14px;
    /* border: 1px solid darkblue; */
}

.profi_item {
    display: flex;
    flex-direction: row;
}

.enter_page {
    margin-top: 12px;
    margin-right: 25px;
    margin-left: 25px;
}

.create-line {
    width: 360px;
    border-top: 1px solid #000000;
  }